// index.js

// Import JS
require('./js/app.js')

// Import SCSS

// funkytown
require('./scss/styles-funkytown-theme.scss');

// ryu
// require('./scss/styles-ryu-theme.scss')
